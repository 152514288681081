<template>
  <div class="container-fluid">
    <Header />
    <div class="mx-auto mt-1 content-outer-container">
      <div class="wrap-UserLevel">
        <div class="mx-auto content-inner-container border-header">
          <div class="col-12 title-header pl-4">
            <div @click="showModal()" class="back-btn mr-3">
              <img src="@/assets/images/userlevel/arrow_back.svg" alt="" />
            </div>
            {{ lbl['user-level-create-member-level'] }}
          </div>
        </div>
        <div class="user-body col-12">
          <div class="body-container">
            <StepProgress
              :step="3"
              :header="progressHeader"
              :desc="progressDesc"
            ></StepProgress>
            <div class="body-main">
              <div class="main-content">
                <div class="content-header">
                  {{ lbl['user-level-member-level-start'] }}
                </div>
                <div class="input-date">
                  <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                      <a-date-picker
                        :placeholder="'dd/mm/yyyy'"
                        :format="dateFormat"
                        v-model="data.programDate"
                        class="w-100 mt-3 mb-4"
                        :disabled-date="disabledStartDate"
                        :disabled="isEdit"
                        @change="handleChangeDate"
                      />
                    </div>
                  </div>
                  <div
                    v-if="data.selectedProgram === 'advance'"
                    class="date-warning"
                  >
                    ***
                    {{ lbl['user-level-member-level-reset-end-year-advance'] }}
                  </div>
                  <div v-else class="date-warning">
                    ***
                    {{ lbl['user-level-member-level-reset-end-year-basic'] }}
                  </div>
                </div>
                <div class="radio-body">
                  <div class="content-header">
                    {{ lbl['user-level-member-level-setting'] }}
                  </div>
                  <div
                    class="radio-main"
                    :class="{ 'disabled-update': isActive }"
                  >
                    <!-- basic -->
                    <div class="radio-container" @click="setSelect('basic')">
                      <div class="radio-header">
                        <div class="check-box">
                          <span
                            class="checkmark"
                            v-if="data.selectedProgram === 'basic'"
                          >
                            <img
                              src="@/assets/images/userlevel/radio_active.svg"
                              alt=""
                            />
                          </span>
                          <span class="checkmark" v-else>
                            <img
                              src="@/assets/images/userlevel/radio_inactive.svg"
                              alt=""
                            />
                          </span>
                          <div class="label">
                            {{ lbl['user-level-basic'] }}
                            <span
                              >({{ lbl['user-level-recommend-basic'] }})</span
                            >
                          </div>
                        </div>
                        <div class="check-desc">
                          {{ lbl['user-level-reset-period'] }}
                        </div>
                      </div>
                      <div class="radio-image">
                        <img
                          src="@/assets/images/userlevel/basic_en@3x.png"
                          alt=""
                        />
                      </div>
                    </div>

                    <!-- advance -->
                    <div class="radio-container" @click="setSelect('advance')">
                      <div class="radio-header">
                        <div class="check-box">
                          <span
                            class="checkmark"
                            v-if="data.selectedProgram === 'advance'"
                          >
                            <img
                              src="@/assets/images/userlevel/radio_active.svg"
                              alt=""
                            />
                          </span>
                          <span class="checkmark" v-else>
                            <img
                              src="@/assets/images/userlevel/radio_inactive.svg"
                              alt=""
                            />
                          </span>
                          <div class="label">
                            {{ lbl['user-level-advance'] }}
                            <span
                              >({{ lbl['user-level-recommend-advance'] }})</span
                            >
                          </div>
                        </div>
                        <div class="check-desc">
                          {{ lbl['user-level-member-tier-detail'] }}
                        </div>
                      </div>
                      <div class="radio-image">
                        <img
                          src="@/assets/images/userlevel/advance_en@3x.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="user-footer col-12 between">
          <div @click="back()" class="previous-btn">
            <img
              class="mr-2"
              src="@/assets/images/userlevel/arrow_back.svg"
              alt=""
            />
            {{ lbl['user-level-previous-button-step-2'] }}
          </div>
          <b-button
            variant="warning"
            class="userlevel-btn"
            :disabled="isDisableNext"
            @click="next()"
          >
            {{ lbl['user-level-button-next'] }}
            <img src="@/assets/images/userlevel/arrow_forward.svg" alt="" />
          </b-button>
        </div>
      </div>
    </div>
    <ModalCancle />
  </div>
</template>

<script>
// mixin
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
// components
import Header from '@/components/Layout/Header'
import StepProgress from '@/module/UserProfile/components/StepProgress.vue'
import ModalCancle from '@/module/UserProfile/modal/CancleModal.vue'
import moment from 'moment'
import dayjs from 'dayjs'

export default {
  name: 'StepThree',
  components: {
    Header,
    StepProgress,
    ModalCancle,
  },
  mixins: [Mixin],
  data: function () {
    return {
      currenttitle: null,
      current: null,
      parent: null,
      progressHeader: '',
      progressDesc: '',
      data: {
        selectedProgram: 'basic',
        programDate: null,
      },
      dateFormat: 'DD/MM/yyyy',
      isDisableNext: true,
      dataUserLevel: {},
      isActive: false,
      isEdit: false,
    }
  },
  created() {
    this.handleFooter(true)
    this.handleLoading(false)
    Account.bzbsAnalyticTracking(
      'member_level_step3',
      'member_level',
      'view_member_level_step3',
      'on view',
    )
    this.init()
  },
  methods: {
    init() {
      this.currenttitle = this.lbl['user-level-member-level']
      this.current = this.lbl['user-level-create-member-level']
      this.progressHeader = this.lbl['user-level-member-level-setting']
      this.progressDesc = this.lbl['user-level-member-level-setting-desc']

      this.dataUserLevel = JSON.parse(localStorage.getItem('dataUserLevel'))
      if (this.dataUserLevel != undefined) {
        if (this.dataUserLevel.levelSetting != undefined) {
          // this.data.programDate = moment(
          //   this.dataUserLevel.levelSetting.programDate,
          // ).format('MM/DD/yyyy')

          this.data.programDate = dayjs(
            this.dataUserLevel.levelSetting.programDate,
          ).format('MM/DD/YYYY')

          this.data.selectedProgram =
            this.dataUserLevel.levelSetting.selectedProgram
          this.isDisableNext = false
        }
        if (this.dataUserLevel.isActive != undefined) {
          this.isActive = this.dataUserLevel.isActive
        }
        if (this.dataUserLevel.isEdit != undefined) {
          this.isEdit = this.dataUserLevel.isEdit
        }
      } else {
        this.$router.push({
          name: 'CreateUserLevel',
        })
      }
    },
    showModal() {
      Account.bzbsAnalyticTracking(
        'member_level_step3',
        'member_level',
        'click_member_level_step3_exit',
        'on click',
      )
      this.$bvModal.show('modalCancle')
    },
    setSelect(program) {
      this.data.selectedProgram = program
    },
    disabledStartDate(current) {
      return current && current.valueOf() < moment().subtract(1, 'days')
    },
    handleChangeDate() {
      if (this.data.programDate != null) {
        this.isDisableNext = false
      } else {
        this.isDisableNext = true
      }
    },
    next() {
      Account.bzbsAnalyticTracking(
        'member_level_step3',
        'member_level',
        'click_member_level_step3_next',
        'on click',
      )
      if (this.dataUserLevel.isEdit != undefined) {
        if (this.dataUserLevel.isEdit == true) {
          this.dataUserLevel.isUpdate = true
        }
      }
      this.dataUserLevel.levelSetting = this.data
      localStorage.setItem('dataUserLevel', JSON.stringify(this.dataUserLevel))
      this.$router.replace({
        name: 'StepFour',
      })
    },
    back() {
      Account.bzbsAnalyticTracking(
        'member_level_step3',
        'member_level',
        'click_member_level_step3_back',
        'on click',
      )
      if (this.dataUserLevel.isEdit != undefined) {
        if (this.dataUserLevel.isEdit == true) {
          this.dataUserLevel.isUpdate = true
        }
      }
      this.dataUserLevel.levelSetting = this.data
      localStorage.setItem('dataUserLevel', JSON.stringify(this.dataUserLevel))
      this.$router.replace({
        name: 'StepTwo',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/module/UserProfile/UI/BaseStyle.scss';
ul,
li {
  padding: 0;
  margin: 0;
}
.user-body {
  padding-bottom: 40px;
  width: 100%;
  .body-container {
    width: 100%;
    .body-main {
      margin: 0 25px;
      .main-content {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        .content-header {
          font-weight: 600;
        }
        .input-date {
          width: 100%;
          margin-bottom: 30px;
          .date-warning {
            width: 100%;
            color: #ff5252;
          }
        }
        .radio-body {
          width: 100%;
          .content-header {
            font-weight: 600;
            margin-bottom: 15px;
          }
          .radio-main {
            display: flex;
            .radio-container {
              border-radius: 12px;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
              border: solid 1px #f5f5f5;
              width: 50%;
              margin-right: 30px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              cursor: pointer;
              .radio-header {
                padding: 16px;
                height: 31%;
                .check-box {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  .label {
                    margin: 0 0 0 5px;
                    span {
                      color: #b4b4b4;
                      font-size: 0.75rem;
                    }
                  }
                }
                .check-desc {
                  margin-left: 28px;
                  font-size: 0.75rem;
                  color: #757575;
                }
              }
              .radio-image {
                width: 100%;
                img {
                  width: 100%;
                  border-bottom-right-radius: 12px;
                  border-bottom-left-radius: 12px;
                }
              }
            }
          }

          @media only screen and (max-device-width: 600px) and (orientation: portrait) {
            .radio-main {
              display: grid;
              .radio-container {
                width: 100%;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
