<template>
  <div class="step-container">
    <div class="progressbar">
      <div class="outer-bar">
        <div
          class="step-bar"
          :class="{
            stepone: step === 1,
            steptwo: step === 2,
            stepthree: step === 3,
            stepfour: step === 4,
          }"
        >
          <div class="inner-bar" :class="{ fullcircle: step === 4 }">
            <span :class="{ fullcircle: step === 4 }">{{ step }} / 4</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content-progressbar">
      <div class="header-progressbar">{{ header }}</div>
      <div class="desc-progressbar">{{ desc }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepProgress',
  components: {},
  props: {
    step: Number,
    header: String,
    desc: String,
  },
  create() {},
  data: function () {
    return {
      stepValue: '',
      headerValue: '',
      descValue: '',
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
$primary-color: #ff9800;
$secondary-color: #ffe0b2;
$dark-color: #424242;
$gray-color: #616161;

.step-container {
  display: flex;
  margin: 30px 25px;
  .progressbar {
    margin-right: 15px;
    .outer-bar {
      height: 79px;
      width: 78px;
      border-radius: 100%;
      border: solid 2px $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      .step-bar {
        height: 77px;
        width: 77px;
        border-radius: 100%;
        border-width: 5px;
        border-style: solid;
        border-top-color: $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotateZ(45deg);
        .inner-bar {
          height: 69px;
          width: 74px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          border: solid 1px $primary-color;
          &.fullcircle {
            background-color: $primary-color;
          }
          span {
            font-size: 1.25rem;
            text-align: center;
            color: $primary-color;
            background-color: #fff;
            font-weight: 600;
            transform: rotateZ(-45deg);
            &.fullcircle {
              color: #fff;
              background-color: $primary-color;
            }
          }
        }
        &.stepone {
          border-top-color: $secondary-color;
          border-right-color: #fff;
          border-bottom-color: #fff;
          border-left-color: #fff;
        }
        &.steptwo {
          border-top-color: $primary-color;
          border-right-color: $secondary-color;
          border-bottom-color: #fff;
          border-left-color: #fff;
        }
        &.stepthree {
          border-top-color: $primary-color;
          border-right-color: $primary-color;
          border-bottom-color: $secondary-color;
          border-left-color: #fff;
        }
        &.stepfour {
          border-top-color: $primary-color;
          border-right-color: $primary-color;
          border-bottom-color: $primary-color;
          border-left-color: $primary-color;
          background-color: $primary-color;
        }
      }
    }
  }
  .content-progressbar {
    padding: 0;
    .header-progressbar {
      padding-bottom: 10px;
      font-size: 1.2rem;
      font-weight: 600;
      color: $dark-color;
    }
    .desc-progressbar {
      font-size: 0.8rem;
      color: $gray-color;
    }
  }
}
</style>